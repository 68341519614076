import React, { useState, useRef, useEffect } from 'react';
import styles from './ProjectModal.css';
import { useDispatch, useSelector } from 'react-redux';
import pythonIcon from '../../assets/img/python-color.svg';
import cppIcon from '../../assets/img/cpp.svg';
import classNames from 'classnames';

import { getMyProjects, loadProject } from '../../reducers/project';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import tabStyles from 'react-tabs/style/react-tabs.css';
import { getMyCodes, loadCode } from '../../reducers/codes';
import { useLocation, useNavigate } from 'react-router-dom';

const s3BucketUrl = 'https://tinkerbrix.s3.ap-south-1.amazonaws.com';

const tabClassNames = {
	tabs: styles.tabs,
	tab: classNames(tabStyles.reactTabsTab, styles.tab),
	tabList: classNames(tabStyles.reactTabsTabList, styles.tabList),
	tabPanel: classNames(tabStyles.reactTabsTabPanel, styles.tabPanel),
	tabPanelSelected: classNames(tabStyles.reactTabsTabPanelSelected, styles.isSelected),
	tabSelected: classNames(tabStyles.reactTabsTabSelected, styles.isSelected),
};

const MyProjectsModal = ({ isVisible, hideModal }) => {
	const dispatch = useDispatch();
	const modalRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();

	const loading = useSelector(state => state.scratchGui.project.loading);
	const projects = useSelector(state => state.scratchGui.project.myProjects);
	const codes = useSelector(state => state.scratchGui.codes.myCodes);
	const isAuthenticated = useSelector(state => state.scratchGui.auth.isAuthenticated);

	useEffect(() => {
		modalRef.current.checked = isVisible;
		if (isVisible) {
			dispatch(getMyProjects());
			dispatch(getMyCodes());
		}
	}, [isVisible]);

	useEffect(() => {
		if (isAuthenticated) dispatch(getMyProjects()); dispatch(getMyCodes());
	}, [isAuthenticated]);

	const loadAProject = projectData => {
		if(location.pathname !== '/scratch'){
			navigate('/scratch')
		}
		// dispatch(loadProject(projectData, hideModal));
		setTimeout(() => {
			dispatch(loadProject(projectData, hideModal));
		}, 100);
	};

	const loadACode = codeData => {
		if(codeData.codeType === "cpp"){
			navigate('/cpp')
		} else {
			navigate('/python')
		}
		dispatch(loadCode(codeData, hideModal));
	};

	return (
		<>
			<input ref={modalRef} type='checkbox' id='my-project-modal' className='modal-toggle' />
			<div className='modal modal-top sm:modal-middle'>
				<div className={`modal-box relative bg-white w-11/12 !max-w-[50rem] min-h-[60dvh] ${styles.loginModal}`}>
					<label onClick={hideModal} className={`absolute right-8 top-6 ${styles.closeBtn}`}>
						✕
					</label>
					<div className={`mt-4 px-5 ${styles.modalBody}`}>
						<h3 className={`mb-[21px] ${styles.loginTitle}`}>My Projects</h3>
						{loading && 'Loading Projects..'}
						{!loading && <Tabs
							className={tabClassNames.tabs}
							// selectedIndex={activeTabIndex}
							selectedTabClassName={`${tabClassNames.tabSelected} !text-[#3b5998] !font-semibold`}
							selectedTabPanelClassName={tabClassNames.tabPanelSelected}
						>
							<TabList className={tabClassNames.tabList}>
								<Tab className={`${tabClassNames.tab} font-medium text-[#c5c6d1] text-base`}>Block Code</Tab>
								<Tab className={`${tabClassNames.tab} font-medium text-[#c5c6d1] text-base`}>C++ Code</Tab>
								<Tab className={`${tabClassNames.tab} font-medium text-[#c5c6d1] text-base`}>Python Code</Tab>
							</TabList>

							<TabPanel className={tabClassNames.tabPanel}>
								{!projects && <h2 className='text-center mt-3 !text-base text-black'>No saved projects found.</h2>}
								<div className={`grid grid-cols-2 gap-[33px] mt-[25px] max-h-[70dvh] ${styles.projectContainer}`}>
									{projects.map((project, index) => (
										<div
											onClick={() => loadAProject(project)}
											className={`${styles.projectCard}`}
											key={index}
										>
											<div className={`${styles.projectImg} bg-[#3b599860] rounded-t-lg py-4 px-3 min-h-40 flex justify-center items-center h-full`}>
												<img src={`${s3BucketUrl}/${project.coverImage}`} alt='' />
											</div>
											<div className={styles.projectCardText}>
												<h3 className='mb-1'>{project.projectName}</h3>
												<p>{project.projectDescription.length > 100 ? project.projectDescription.slice(0, 100) : project.projectDescription}</p>
											</div>
										</div>
									))}
								</div>
							</TabPanel>
							<TabPanel className={tabClassNames.tabPanel}>
								{codes?.filter(v => v.codeType === "cpp").length === 0 && <h2 className='text-center mt-3 !text-base text-black'>No saved projects found.</h2>}
								<div className={`grid grid-cols-2 gap-[33px] mt-[25px] ${styles.projectContainer}`}>
									{codes?.filter(v => v.codeType === "cpp").map((code, index) => (
										<div
											onClick={() => loadACode(code)}
											className={styles.projectCard}
											key={index}
										>
											<div className={`${styles.projectImg} bg-[#3b599860] rounded-t-lg py-4 px-3 min-h-40 flex justify-center items-center h-full`}>
												<img src={cppIcon} alt='' className='w-[30%] max-w-[120px]' />
											</div>
											<div className={styles.projectCardText}>
												<h3>{code.codeName}</h3>
											</div>
										</div>
									))}
								</div>
							</TabPanel>
							<TabPanel className={tabClassNames.tabPanel}>
								{codes?.filter(v => v.codeType === "python").length === 0 && <h2 className='text-center mt-3 !text-base text-black'>No saved projects found.</h2>}
								<div className={`grid grid-cols-2 gap-[33px] mt-[25px] ${styles.projectContainer}`}>
									{codes?.filter(v => v.codeType === "python").map((code, index) => (
										<div
											onClick={() => loadACode(code)}
											className={styles.projectCard}
											key={index}
										>
											<div className={`${styles.projectImg} bg-[#3b599860] rounded-t-lg py-4 px-3 min-h-40 flex justify-center items-center h-full`}>
												<img src={pythonIcon} alt='' className='w-[30%] max-w-[120px]' />
											</div>
											<div className={styles.projectCardText}>
												<h3>{code.codeName}</h3>
											</div>
										</div>
									))}
								</div>
							</TabPanel>
						</Tabs>}
					</div>
				</div>
			</div>
		</>
	);
};

export default MyProjectsModal;
