import React from 'react';
import ROBOTICS_ICON from '../../../assets/robotics_icon.svg';
import BLOCK_CODING_ICON from '../../../assets/block_coading_icon.png';
import ELECTRONICS_ICON from '../../../assets/electronics_icon.png';
import STEM_ICON from '../../../assets/stem_icon.svg';
import PYTHON_ICON from '../../../assets/img/python-color.svg';
import CPP_ICON from '../../../assets/img/cpp.svg';
import PHYSICS_ICON from '../../../assets/img/physics.png';
import Download from '../../../assets/download_icon.svg';
import styles from './Subjects.css';
import { Link } from 'react-router-dom';

const subjectList = [
	{ 
		title: 'Block Coding', 
		page: '/scratch',
		icon:  BLOCK_CODING_ICON
	},
	{ 
		title: 'Robotics and AI', 
		page: '/scratch',
		icon:  ROBOTICS_ICON
	},
	{ 
		title: 'Electronics', 
		page: '/circuits',
		icon:  ELECTRONICS_ICON
	},
	// { title: 'Extended Reality', 
	// 	page: '/xr',
	// 	icon:  
	// },
	{ 
		title: 'STEM Basics', 
		page: '/stem',
		icon:  STEM_ICON
	},
	{ 
		title: 'Python', 
		page: '/python',
		icon:  PYTHON_ICON
	},
	{ 
		title: 'C ++', 
		page: '/cpp',
		icon:  CPP_ICON
	},
	{ 
		title: 'Physics Engine', 
		page: '/physics',
		icon:  PHYSICS_ICON
	},
];

// const botsImages = [Bot1, Bot2, Bot3];

const Subjects = () => {
	return (
		<section className={`${styles.subjects} mt-[100px]`}>
			<div
				className={`px-6 lg:px-12 lg:flex justify-around flex-wrap ${styles.subjectContainer} items-center`}
			>
				{subjectList.map((subject, index) => (
					<React.Fragment key={index}>
						{subject.page !== '/physics' ? 
							<Link to={subject.page} className='block mx-auto'>
								<div className={`
									${styles.subject} 
									md-3 
									`}>
									<div className={`${styles.subjectImg}`}>
										<img src={subject.icon} alt='' className={`object-contain w-[55%]`} />
									</div>							
									<h3>{subject.title}</h3>
								</div>
							</Link>
						: 
							<div to={subject.page} className='block mx-auto'>
								<div className={`
									${styles.subject} 
									md-3 
									`}>
									<div className={`${styles.subjectImg} relative`}>
										<img src={subject.icon} alt='' className={`object-contain w-[55%]`} />
										<div className='flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 bg-[#FFFFFF80] rounded-[8px]'>
											<h3 className='uppercase text-center' style={{ fontFamily: "Poppins, 'sans serif'", fontWeight: '900', fontSize: '1.75rem', color: '#000000' }}>Coming Soon...</h3>
										</div>
									</div>							
									<h3 className='opacity-50'>{subject.title}</h3>
								</div>
							</div>
						}
					</React.Fragment>
				))}
			</div>
			<div style={{width: '100%', display: 'flex'}}>
				<Link 
					to="https://drive.google.com/uc?export=download&id=1FMUOEJncwjfc7Fuu_CIA_PiEEQCmdub6" 
					target='_blank' 
					style={{
						boxSizing: 'border-box',
						marginInline: 'auto', 
						background: '#3b5998',
						display: 'flex',
						alignItems: 'center',
						color: '#FFFFFF',
						gap: '0.5rem',
						padding: '0.75rem 1rem',
						borderRadius: '0.5rem',
						boxShadow: '4px 2px 10px 1px #3b599880',
						marginBottom: '2rem',
						fontFamily: 'Karla',
						fontWeight: '600'
					}}
				>
					<img src={Download} alt='download' style={{width: '24px', height: '24px', color: '#1d1d1d'}} /> Download Offline Software
				</Link>
			</div>
		</section>
	);
};

export default Subjects;
