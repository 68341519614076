import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Box from '../box/box.jsx';
import PeripheralTile from './peripheral-tile.jsx';
import Dots from './dots.jsx';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import radarIcon from './icons/searching.png';
import refreshIcon from './icons/refresh.svg';

import styles from './connection-modal.css';
import tabStyles from 'react-tabs/style/react-tabs.css';

const tabClassNames = {
	tabs: styles.tabs,
	tab: classNames(tabStyles.reactTabsTab, styles.tab),
	tabList: classNames(tabStyles.reactTabsTabList, styles.tabList),
	tabPanel: classNames(tabStyles.reactTabsTabPanel, styles.tabPanel),
	tabPanelSelected: classNames(tabStyles.reactTabsTabPanelSelected, styles.isSelected),
	tabSelected: classNames(tabStyles.reactTabsTabSelected, styles.isSelected),
};
const ScanningStep = props => (
    <Box className={styles.body}>
        {props.isSerialport ? (
            <Box className={classNames(styles.bodyHeadArea)}>
                <div className={styles.listAll}>
                    <FormattedMessage
                        defaultMessage="Show all connectable devices"
                        description="Button in prompt for show all connectable devices"
                        id="gui.connection.scanning.listAll"
                    />
                </div>
                <div className={styles.checkBox}>
                    <input
                        type="checkbox"
                        name="hexform"
                        checked={props.isListAll}
                        onChange={props.onClickListAll}
                    />
                </div>
            </Box>
        ) : null}
        <Box className={styles.activityArea}>
            {props.scanning ? (
                props.peripheralList.length === 0 ? (
                    <div className={styles.activityAreaInfo}>
                        <div className={styles.centeredRow}>
                            <img
                                className={classNames(styles.radarSmall, styles.radarSpin)}
                                src={radarIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Looking for devices"
                                description="Text shown while scanning for devices"
                                id="gui.connection.scanning.lookingforperipherals"
                            />
                        </div>
                    </div>
                ) : (
                    <div className={styles.peripheralTilePane}>
                        <Tabs
                            className={tabClassNames.tabs}
                            // selectedIndex={activeTabIndex}
                            selectedTabClassName={`${tabClassNames.tabSelected} !text-black`}
                            selectedTabPanelClassName={tabClassNames.tabPanelSelected}
                        >
                            <TabList className={tabClassNames.tabList}>
                                <Tab className={`${tabClassNames.tab} font-medium text-[#c5c6d1] text-sm`}>Serial</Tab>
                                <Tab className={`${tabClassNames.tab} font-medium text-[#c5c6d1] text-sm`}>Bluetooth</Tab>
                            </TabList>
                            <TabPanel className={tabClassNames.tabPanel}>
                                {props.peripheralList.filter(v => !v.name.toLowerCase().includes("bluetooth")).length === 0 ? 
                                    <Box className={styles.instructions}>
                                        <FormattedMessage
                                            defaultMessage="No devices found"
                                            description="Text shown when no devices could be found"
                                            id="gui.connection.scanning.noPeripheralsFound"
                                        />
                                    </Box>
                                    :
                                    props.peripheralList.filter(v => !v.name.toLowerCase().includes("bluetooth")).map(peripheral =>
                                        <PeripheralTile
                                        connectionSmallIconURL={props.connectionSmallIconURL}
                                        key={peripheral.peripheralId}
                                        name={peripheral.name}
                                        peripheralId={peripheral.peripheralId}
                                        rssi={peripheral.rssi}
                                        isSerialport={props.isSerialport}
                                        onConnecting={props.onConnecting}
                                        />     
                                    )                    
                                }
                            </TabPanel>
                            <TabPanel className={tabClassNames.tabPanel}>
                                {props.peripheralList.filter(v => v.name.toLowerCase().includes("bluetooth")).length === 0 ? 
                                    <Box className={styles.instructions}>
                                        <FormattedMessage
                                            defaultMessage="No devices found"
                                            description="Text shown when no devices could be found"
                                            id="gui.connection.scanning.noPeripheralsFound"
                                        />
                                    </Box>
                                    :
                                    props.peripheralList.filter(v => v.name.toLowerCase().includes("bluetooth")).map(peripheral =>
                                        <PeripheralTile
                                        connectionSmallIconURL={props.connectionSmallIconURL}
                                        key={peripheral.peripheralId}
                                        name={peripheral.name}
                                        peripheralId={peripheral.peripheralId}
                                        rssi={peripheral.rssi}
                                        isSerialport={props.isSerialport}
                                        onConnecting={props.onConnecting}
                                        />     
                                    )                    
                                }                    
                            </TabPanel>
                        </Tabs>
                    </div>
                )
            ) : (
                <Box className={styles.instructions}>
                    <FormattedMessage
                        defaultMessage="No devices found"
                        description="Text shown when no devices could be found"
                        id="gui.connection.scanning.noPeripheralsFound"
                    />
                </Box>
            )}
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                <FormattedMessage
                    defaultMessage="Select your device in the list above."
                    description="Prompt for choosing a device to connect to"
                    id="gui.connection.scanning.instructions"
                />
            </Box>
            <Dots
                className={styles.bottomAreaItem}
                counter={0}
                total={3}
            />
            <button
                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                onClick={props.onRefresh}
            >
                <FormattedMessage
                    defaultMessage="Refresh"
                    description="Button in prompt for starting a search"
                    id="gui.connection.search"
                />
                <img
                    className={styles.buttonIconRight}
                    src={refreshIcon}
                />
            </button>
        </Box>
    </Box>
);

ScanningStep.propTypes = {
    connectionSmallIconURL: PropTypes.string,
    isListAll: PropTypes.bool.isRequired,
    isSerialport: PropTypes.bool,
    onClickListAll: PropTypes.func.isRequired,
    onConnecting: PropTypes.func,
    onRefresh: PropTypes.func,
    peripheralList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        rssi: PropTypes.number,
        peripheralId: PropTypes.string
    })),
    scanning: PropTypes.bool.isRequired
};

ScanningStep.defaultProps = {
    peripheralList: [],
    scanning: true
};

export default ScanningStep;
